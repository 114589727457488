<template>
  <a @click="openQuiz" class="quiz-list-item box" :class="isSmallClass">
    <div class="columns is-mobile is-vcentered is-gapless has-fullwidth">
      <div class="column is-narrow">
        <div class="quiz-icon-container" :class="isSmallClass">
          <component :is="quizIcon" class="has-fill-primary svg-icon"></component>
        </div>
      </div>
      <div class="column">
        <div class="quiz-list-item-title mr-1" :class="isSmallClass">
          {{ title }}
        </div>
      </div>
      <div class="column is-narrow">
        <div class="is-flex is-justify-content-center is-align-content-center">
          <component class="has-stroke-primary" :is="navigationIcon"></component>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import QuizIcon from "@/assets/quizzes/quiz.svg";
import SurveyIcon from "@/assets/quizzes/survey.svg";
import CompletedIcon from "@/assets/quizzes/nav_completed.svg";
import UncompletedIcon from "@/assets/quizzes/nav_uncompleted.svg";
import Constants from "@/web/constants";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "QuizItem",

  props: ["quiz", "isSmall"],

  computed: {
    title() {
      return this.quiz.name;
    },

    isCompleted() {
      return this.quiz.is_completed_by_me;
    },

    quizIcon() {
      if (this.quiz.mode === Constants.QUIZ_MODE.QUIZ) {
        return QuizIcon;
      } else {
        return SurveyIcon;
      }
    },

    navigationIcon() {
      if (this.isCompleted) {
        return CompletedIcon;
      } else {
        return UncompletedIcon;
      }
    },

    routerLink() {
      return {
        name: Constants.ROUTE_QUIZ_QUESTIONS,
        params: { componentId: this.quiz.event_component_id, quizId: this.quiz.id },
      };
    },

    isSmallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },
  },

  methods: {
    ...mapActions("sideMenu", ["openQuizBubble"]),

    openQuiz() {
      if (this.isSmall) {
        this.$emit("start-quiz", this.quiz.id);
      } else {
        this.openQuizBubble(this.quiz.id);
      }
    },
  },
};
</script>

<style scoped>
.box {
  height: 100%;
}

.column {
  overflow: hidden;
}
</style>
